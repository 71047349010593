<template>
    <Header/>
        <div class="bg-gray-100">
            <Title name="Contact"/>
            <div class=" mx-3 md:mx-16 py-16">
                <div class="bg-white rounded-md p-3 lg:p-8" >
                    <div>
                        <h1 class="text-2xl font-semibold" >Contact us</h1>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
                        <div class="flex space-x-4 border border-gray-300 rounded-md p-5" >
                            <div class="h-16 w-16 flex-shrink-0 bg-brand flex justify-center items-center rounded-full">
                               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <div class="flex items-center" >
                                <div>
                                    <p class="font-semibold"><a href="mailto:support@noshtrybe.com">support@noshtrybe.com</a></p>
                                    <p class="text-xs">E-mail Address</p>
                                </div>     
                            </div>
                        </div>
                        <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                            <div class="h-16 w-16 bg-brand flex justify-center items-center rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                            </div>
                            <div class="flex items-center" >
                                <div>
                                    <p class="font-semibold" > <a href="tel:+2340700700674">0700700NOSH</a></p>
                                    <p class="text-xs">Phone Number</p>
                                </div>     
                            </div>
                        </div>
                        <div class=" flex space-x-4 border border-gray-300 rounded-md p-5" >
                            <div class="h-16 w-16 bg-brand flex justify-center items-center rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </div>
                            <div class="flex items-center" >
                                <div>
                                    <p class="font-semibold">71, CMD Road, Lagos</p>
                                    <p class="text-xs">Address</p>
                                </div>     
                            </div>
                        </div>
                    </div>
                    <div class="my-24"> 
                        <div class="text-center" >
                             <h1 class="text-2xl font-semibold" >Send us a message</h1>
                            <p class="text-sm">We'll reply as soon as we can</p>
                        </div>
                        <div class="flex justify-center">
                            <form class="w-full lg:w-3/5 mt-8" >
                                <div class="md:grid md:grid-cols-2 md:gap-2 mb-3">
                                    <div>
                                        <label for="fullname" class="text-sm" >First Name</label>
                                        <input type="text" name="firstName" class="input" placeholder="Enter First Name">
                                    </div>  
                                    <div class="mt-4 md:mt-0" >
                                        <label for="fullname" class="text-sm" >Last Name</label>
                                        <input type="text" name="lastName" class="input" placeholder="Enter Last Name">
                                    </div>  
                                </div> 
                                <div class="md:grid md:grid-cols-2 md:gap-2 mb-3">
                                    <div>
                                        <label for="fullname" class="text-sm" >Email Address</label>
                                        <input type="text" name="email" class="input" placeholder="Enter E-mail address">
                                    </div>  
                                    <div class="mt-4 md:mt-0" >
                                        <label for="fullname" class="text-sm" >Phone Number</label>
                                        <input type="text" name="phone"  class="input" placeholder="Enter Phone Number">
                                    </div>  
                                </div> 
                                <div>
                                    <label for="Message" class="text-sm" >Message</label>
                                    <textarea type="text" maxlength="200" cols="10" rows="6" class="input" placeholder="Enter Message"></textarea>
                                </div>
                                <button class="bg-brand px-4 py-3 mt-4 text-sm rounded  font-semibold" >Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer/>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Title from "@/components/Title.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
export default{
    name:"Contact",
    components:{
        Header,Footer,Title,BreadCrumb
    }
}
</script>